<template>
    <div class="box content">
        
        <p class="title is-5 has-text-centered">Pre-existing Medical Conditions</p>

        <div class="columns">
            <div class="column is-2">
                <i class="fas fa-first-aid"/>
            </div>
            <div class="column is-10">
                <p>
                    Covered2go may provide cover for pre-existing medical conditions, so long as these have been declared to and accepted by our Medical Screening Service.
                </p>
                <p>
                    You can complete your medical screening online, <a href="https://ctg.millstreamonline.com/" target="_blank">simply click here to get started</a>. The link will open a new webpage and you can begin screening immediately.
                </p>
                <p>
                    You can arrange cover for pre-existing medical conditions before or after you purchase your Covered2go Policy, but please note, two transactions will be required i.e., to purchase the policy for medical cover and your Covered2go Policy.   
                </p>
                <p>
                    We will be integrating medical screening into our customer journey soon, to further enhance your online experience and we appreciate your patience with us while we get this in place.
                </p>
                <p>
                If you would prefer to speak to the Medical Screening Service, please call +44 (0)330 311 2648.
                </p>
            </div>
        </div>

        <p>
            If you have already declared your conditions and have a reference number, please enter it here for our
            records. If you have not declared your conditions, you can add your reference number here when you have
            completed the disclosure of your medical conditions.
        </p>

        <div v-if="policy">
            <input type="text" class="input" placeholder="">
            <br><br>
            <button class="button is-info is-fullwidth" @click="save">Update</button>
            <br><br>
        </div>
        <p>Please leave the box blank if you do not need to declare any medical conditions.</p>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import { policyAPI } from "../../../mixins/policy-api";

    export default {
        name: "CustomerMedicalScreening",
        data() {
            return {
                policy: null
            }
        },
        methods: {
            save() {
                this.savePolicy(this.policy)
                .then(res => {
                    this.policy = res.data;
                    this.$buefy.toast.open({
                        duration: 4000,
                        message: `Your medical screening number was updated!`,
                    });
                })
                .catch(err => console.log(err));
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
        mixins: [ policyAPI ],
        created() {
            this.$http.post('customer/policies', {
                'username': this.customer.email,
                'type': 'policy'
            })
            .then(res => {
                // Get the policy object
                
                this.policy = res.data[0];
            })
            .catch(err => console.log(err));
        }
    }
</script>

<style lang="scss" scoped>
    .fa-first-aid {
        font-size: 100px;
        color: $c2g_blue;
    }
    .button {
        margin-left: 5px;
        margin-right: 5px;
    }
</style>