<template>
    <div class="box">
        <p class="title is-5 has-text-centered">Edit Profile</p>
        <p class="text-info">Below are the details we have stored on you.</p>

        <div class="box">
            <form @submit.prevent="save">
                <div class="columns is-multiline">
                    <div class="column title-col is-half">Address:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.address1">
                    </div>

                    <div class="column title-col is-half">Town:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.town">
                    </div>

                    <div class="column title-col is-half">County:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.county">
                    </div>

                    <div class="column title-col is-half">Postcode:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.postcode">
                    </div>

                    <div class="column title-col is-half">Telephone:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.telephone">
                    </div>

                    <div class="column title-col is-half">Mobile:</div>
                    <div class="column is-half">
                        <input type="text" class="input" v-model="customer.mobile">
                    </div>
                </div>

                <button class="button is-primary is-fullwidth">Save Changes</button>
            </form>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";

    export default {
        name: "CustomerProfileEdit",
        methods: {
            ...mapActions({
                setCustomer: 'setCustomer',
            }),
            save() {
                this.$http.post('customer/update_account', {
                    'email': this.customer.email,
                    'address': this.customer.address1,
                    'town': this.customer.town,
                    'county': this.customer.county,
                    'postcode': this.customer.postcode,
                    'telephone': this.customer.telephone,
                    'mobile': this.customer.mobile
                })
                    .then(() => {
                        return this.$http.post('customer/get_account', {
                            'username': this.customer.email,
                        })
                    })
                    .then(res => {
                        this.setCustomer(JSON.parse(res.data));
                        this.$buefy.toast.open({
                            duration: 4000,
                            message: `Your details were updated`,
                        });
                        this.$router.push({name: 'customerProfile'});
                    })
                    .catch(err => console.log(err))
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
    }
</script>

<style scoped>
    .text-info {
        margin-bottom: 10px;
        text-align: center;
    }
    .title-col {
        font-weight: bold;
    }
</style>