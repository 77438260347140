import { render, staticRenderFns } from "./CustomerUpgradeForm.vue?vue&type=template&id=772fa138&scoped=true"
import script from "./CustomerUpgradeForm.vue?vue&type=script&lang=js"
export * from "./CustomerUpgradeForm.vue?vue&type=script&lang=js"
import style0 from "./CustomerUpgradeForm.vue?vue&type=style&index=0&id=772fa138&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "772fa138",
  null
  
)

export default component.exports