<template>
<div>
    <div class="level-item header-section">
            <h4 class="title is-3 page-title">Welcome to your Customer Hub {{customer.title}} {{customer.surname}}!</h4>
        </div>
    <div class="box">
        <!-- <p class="title is-4 has-text-centered">{{dayMessage}} {{customer.title}} {{customer.surname}}!</p> -->

        <div class="columns content">
            <!-- <div class="column is-2">
                <i class="fas fa-check-square active-policy-icon"/>
            </div> -->
            <div class="column is-10">
                <div class="top-text" v-if="currentPolicy && $moment(new Date()).isBefore(currentPolicy.policy.end_date)">
                    <p>You currently have an active policy with us that expires on <strong>{{ $moment(currentPolicy.policy.end_date).format('DD/MM/YYYY') }}</strong></p>
                    <br>

                    <div class="box" v-if="currentPolicy.policy.covid19 === 'N'">
                        <p>This policy doesn't have COVID-19 cover, would you like to upgrade?</p>
                        <router-link tag="button" :to="{name: 'customerUpgradePolicy', params: { id: currentPolicy.quote.id }}" class="button upgrade-button is-info is-fullwidth">
                            <i class="fas fa-file-upload"/> Upgrade Policy
                        </router-link>
                    </div>
                    
                    <p>To assist in the administration of your policy - from this members area - you can:</p>
                </div>
                
                <ul>
                    <h4>How can we help?</h4>
                    <li><router-link :to="{name:'customerPolicies'}">View your current and expired policies.</router-link></li>
                    <li><router-link :to="{name:'customerPolicies'}">Download your documentation.</router-link></li>
                    <li><router-link :to="{name:'customerPolicies'}">Resend your documents via email.</router-link></li>
                    <li><router-link :to="{name:'customerPolicies'}">Upgrade your policy if your travel arrangements have changed.</router-link></li>
                    <li><router-link :to="{name:'customerProfileEdit'}">Update your contract details.</router-link></li>
                    <li><router-link :to="{name:'claim'}">Make a claim.</router-link></li>
                    <li><router-link :to="{name:'faqs'}">FAQs.</router-link></li>
                    <li><router-link :to="{name:'getQuote'}">Get a quote for your next trip.</router-link></li>
                    <li><router-link :to="{name:'customerFeedback'}">Submit any feedback.</router-link></li>
                    <li><a href="https://uk.trustpilot.com/evaluate/covered2go.co.uk" target="_blank">Leave a review.</a></li>
                </ul>
            </div>
        </div>

    </div>
</div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "CustomerHome",
        data() {
            return {
                currentPolicy: null,
                dayMessage: ''
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
        created() {
            const today = new Date()
            const curHr = today.getHours()
            if (curHr < 12) {
                this.dayMessage = 'Good morning';
            } else if (curHr < 18) {
                this.dayMessage = 'Good afternoon';
            } else {
                this.dayMessage = 'Good evening';
            }

            this.$http.post('customer/policies', {
                'username': this.customer.email,
                'type': 'policy',
                'limit': 1
            })
            .then(res => {
                this.currentPolicy = JSON.parse(res.data)[0];
                console.log(this.currentPolicy);
            })
            .catch(err => console.log(err));
        }
    }
</script>

<style lang="scss" scoped>
    .top-text {
        margin-bottom: 10px;
    }
    .title {
        margin-bottom: 15px;
        color: $c2g_orange;
    }
    li {
        margin-left: 25px;
        margin-bottom: 10px;
        position: relative;
        list-style-type: none;
        font-weight: bold;
    }
    a {
        color: $c2g_orange !important;
    }
    li:before {
        content: "";
        position: absolute;
        top: 3px;
        left: -25px;
        width: 20px;
        height: 20px;
        background-image: url('../../../assets/images/icons/bullet-tick-sm.png');
    }
    .active-policy-icon {
        font-size: 100px;
        color: $c2g_blue;
    }

    .upgrade-button {
        margin-top: 5px;

        .fas {
            margin-right: 5px;
        }
    }
</style>