<template>
    <div class="box">
        <p class="title is-5 has-text-centered">Policy Wordings</p>
        <policy-wording/>
    </div>
</template>

<script>
    import PolicyWording from "../../policy-wordings/PolicyWordingMUL";

    export default {
        name: "CustomerPolicyWording",
        components: {
            PolicyWording
        }
    }
</script>

<style scoped>

</style>