<template>
    <div class="box">
        <p class="title is-5 has-text-centered">Change Password</p>

        <div class="columns">
            <div class="column is-4">
                Current Password:
            </div>
            <div class="column is-7">
                <input type="password" class="input" placeholder="Enter your current password" v-model="currentPassword">
                <div class="error-message" v-show="error">{{ error }}</div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4">
                New Password:
            </div>
            <div class="column is-7">
                <input type="password" class="input" placeholder="Enter your new password" v-model="newPassword">

                <div class="error-message" v-for="a in validationMessage" :key="a.id">{{ a }}</div>
            </div>
        </div>

        <div class="columns">
            <div class="column is-4">
                Confirm Password:
            </div>
            <div class="column is-7">
                <input type="password" class="input" placeholder="Enter your new password again" v-model="confirmPassword">
            </div>

        </div>

        <button class="button is-info is-fullwidth" @click="save">Update Password</button>

    </div>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: "CustomerChangePassword",
        data() {
            return {
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
                validationMessage: [],
                error: null
            }
        },
        watch: {
            newPassword(v) {
                const validation = [];
                if (v.length < 10) {
                    validation.push('Password must be over 10 characters');
                }
                if (!v.match(/[a-z]/g)) {
                    validation.push('Password must contain at least 1 lowercase letter');
                }
                if (!v.match(/[A-Z]/g)) {
                    validation.push('Password must contain at least 1 uppercase letter');
                }
                if (!v.match(/[0-9]/g)) {
                    validation.push('Password must contain at least 1 number');
                }
                this.validationMessage = validation;
            }
        },
        methods: {
            save() {
                this.$http.post('customer/update_password', {
                    'email': this.customer.email,
                    'currentPassword': this.currentPassword,
                    'newPassword': this.newPassword,
                })
                .then(res => {
                    if (res.data === 'error') {
                        this.error = 'Your current password doesn\'t match';

                    } else if (this.validationMessage.length > 0) {
                        this.error = null;

                    } else {
                        this.error = null;
                        this.$buefy.toast.open({
                            duration: 4000,
                            message: `Your password was updated`,
                        });
                    }
                })
                .catch(err => console.log(err));
            }
        },
        computed: {
            ...mapGetters({
                customer: 'customer',
            })
        },
    }
</script>

<style scoped>
    .error-message {
        margin-top: 5px;
        color: red;
    }
</style>