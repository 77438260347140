var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('p',{staticClass:"title is-5 has-text-centered"},[_vm._v("My Quotes")]),_c('p',{staticClass:"text-info"},[_vm._v(" If your travel insurance quote was issued over 14 days ago, it will have expired. ")]),_c('p',{staticClass:"text-info"},[_c('router-link',{attrs:{"to":{ name: 'getQuote' }}},[_vm._v("Click here to get a new quote.")])],1),_c('div',{staticClass:"policies-container"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.loading,"can-cancel":true}}),_c('table',{staticClass:"table is-hoverable is-fullwidth"},[_vm._m(0),_c('tbody',_vm._l((_vm.policies),function(p){return _c('tr',{key:p.id},[_c('td',[_vm._v("C2G6283"+_vm._s(p.id))]),_c('td',[_vm._v(_vm._s(_vm.$c2g.policyType[p.type]))]),_c('td',[_vm._v(_vm._s(_vm.$moment(p.issue_date).format('DD/MM/YYYY')))]),_c('td',[_vm._v(_vm._s(_vm.$moment(p.start_date).format('DD/MM/YYYY')))]),_c('td',[_vm._v(_vm._s(_vm.$moment(p.end_date).format('DD/MM/YYYY')))]),(p.status === 'policy')?_c('td',[_vm._v(" Purchased ")]):(
              _vm.$moment(p.start_date).isSameOrAfter(
                _vm.$moment(new Date().toISOString().split('T')[0])
              )
            )?_c('td',[_vm._v(" Live ")]):_c('td',[_vm._v(" Expired ")]),(p.status === 'policy')?_c('td',[_c('button',{staticClass:"button is-small is-primary",staticStyle:{"background-color":"blue"}},[_vm._v(" PURCHASED ")])]):_vm._e(),(
              _vm.$moment(p.start_date).isSameOrAfter(
                _vm.$moment(new Date().toISOString().split('T')[0])
              )
            )?_c('td',[_c('router-link',{staticClass:"button is-small is-primary",attrs:{"tag":"button","to":{ name: 'getQuote' }}},[_vm._v("PURCHASE")])],1):_c('td',[_c('button',{staticClass:"button is-small is-primary",on:{"click":function($event){return _vm.createNewQuoteAndNavigate(p)}}},[_vm._v(" NEW QUOTE ")])])])}),0)])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Policy Number")]),_c('th',[_vm._v("Policy Type")]),_c('th',[_vm._v("Issue Date")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("End Date")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Options")])])])
}]

export { render, staticRenderFns }