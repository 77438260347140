<template>
    <div class="">
        <div class="text-center">
            <h4 class="title">UPGRADE COMPLETE</h4>
            <p class="has-text-weight-bold">Thank you for upgrading your policy with Covered2go Travel Insurance.</p>
            <p class="text-c2g-orange has-text-weight-bold">Download your documents:</p>
            <div class="level box docs-section">
                <div class="level-item">
                    <a :href="certLink" target="_blank"><i class="fas fa-file-pdf"/> Download your Certificate</a>
                </div>
                <div class="level-item">
                    <a :href="pwLink" target="_blank"><i class="fas fa-file-pdf"/> View Policy Wording</a>
                </div>
                <div class="level-item">
                    <a :href="sdLink" target="_blank"><i class="fas fa-file-pdf"/> View the Status Disclosure</a>
                </div>
            </div>
            <p class="text-center has-text-weight-bold">Your payment receipt has been emailed to you and your upgraded travel insurance documents are available to download immediately here.</p>
            <p class="text-center has-text-weight-bold">Your documents are also available to view in the Customer Hub, under the <a href="/customer-hub/policies">My Policies</a> section.</p>
            <p class="text-center has-text-weight-bold">If we can be of any further assistance, then please do not hesitate to <a href="/customer-hub/contact-us">Contact Us</a>.</p>
            <p class="text-center has-text-weight-bold">Stay safe and enjoy your travels.</p>
        </div>
    </div>
</template>

<script>
    import { policyAPI } from "@/mixins/policy-api";

    export default {
        name: "UpgradeSuccess",
        data() {
            return {
                certLink: '',
                pwLink: '',
                sdLink: ''
            }
        },
        props: ['id'],
        mixins: [ policyAPI ],
        mounted() {
            const [ policynum ] = this.id.split("-");

            if(policynum !== null) {
                // Get the certificate link from the API
                this.certificateLink(policynum).then((res) => {
                    this.certLink = res.data;
                });
                // Get the policy wording link
                this.policyWordingLink(policynum).then((res) => {
                    this.pwLink = res.data;
                });
                // Get the status disclosure link
                this.statusDisclosureLink().then((res) => {
                    this.sdLink = res.data;
                });
            }

        }
    }
</script>

<style lang="scss" scoped>
    p {
            margin-top: 10px;
            margin-bottom: 10px;
    }
    h4 {
        padding-bottom: 7px;
        border-bottom: $c2g_orange 4px solid;
        margin-bottom: 5px !important;
        font-size: 28px;
    }
    h5 {
        color: $c2g_orange;
    }
		// Documents section
    .docs-section {
        background-color: $c2g_orange;
        .level-item {
            a {
                color: white;
                font-weight: bold;
            }
        }
    }
</style>