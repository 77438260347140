export const contentAPI = {
    methods: {
        getContent(page) {
            return this.$httpContent.get(`page/covered2go/${page}`)
            .catch((error) => {
                console.log(error);
            });
        },
        getBlog(blogId) {
            return this.$httpContent.get(`blog/covered2go/${blogId}`)
            .catch((error) => {
                console.log(error);
            });
        },
    }
};